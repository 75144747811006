<template>
  <div>
    <b-row class="match-height">
      <!-- Trofeu -->
      <b-col
        xl="4"
        md="6"
      >
        <b-card
          class="card-congratulation-medal"
        >
          <h5>Bem vindo 🎉 {{ nameAdmin.username }}!</h5>
          <b-card-text class="font-small-3">
            Bom trabalho bro!!
          </b-card-text>
          <div>
            <h3 class="mb-75 mt-2 pt-50">
              <b-link>
                {{ nameAdmin.credits }}
              </b-link>
            </h3>
            <b-button variant="primary">
              Comprar Créditos
            </b-button>
          </div>

          <b-img
            :src="require('@/assets/images/illustration/badge.svg')"
            class="congratulation-medal"
            alt="Medal Pic"
          />
        </b-card>
      </b-col>
      <!-- Trofeu -->

      <!-- Estatísticas-->
      <b-col style="width: 50%">
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Estátisticas</b-card-title>
            <b-card-text class="mr-25 mb-0">
              Atualizado à 1 minuto atrás
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in statisticsItems"
                :key="item.icon"
                md="3"
                sm="6"
                class="mb-2 mb-md-0"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- Estatísticas -->
    </b-row>
    <!-- Listagem dos ultimos cadastros -->
    <b-card>
      <div class="row match-height">
        <div class="col-lg-12 col-12">
          <div class="card card-company-table">
            <div class="card-body p-0">
              <b-alert
                show
                variant="success"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="StarIcon"
                  />
                  <span
                    class="ml-10"
                  >Últimos Cadastros</span>
                </div>
              </b-alert>
              <div
                class="table-responsive"
              >
                <table class="table">
                  <thead />
                  <thead>
                    <tr>
                      <th>GRUPO</th>
                      <th>LINK DO GRUPO</th>
                      <th>STATUS</th>
                      <th>AÇÕES</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="lastgroup in groups"
                    :key="lastgroup.id"
                  >

                    <tr>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="avatar rounded">
                            <div class="avatar-content">
                              <img
                                src="@/assets/images/icons/star.svg"
                                alt="Star svg"
                              >
                            </div>
                          </div>
                          <div style="margin-left: 10px">
                            <div
                              class="font-weight-bolder"
                            >
                              {{ lastgroup.name }}
                            </div>
                            <div class="font-small-2 text-muted">
                              {{ lastgroup.type }}
                            </div>

                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="avatar bg-light-primary mr-1">
                            <div class="avatar-content">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21px"
                                height="21px"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-credit-card"
                              ><rect
                                x="1"
                                y="4"
                                width="22"
                                height="16"
                                rx="2"
                                ry="2"
                              /><line
                                x1="1"
                                y1="10"
                                x2="23"
                                y2="10"
                              /></svg>
                            </div>
                          </div>
                          <a
                            target="_bank"
                            :href="lastgroup.link"
                          >{{ lastgroup.link }}</a>
                        </div>
                      </td>

                      <td class="text-nowrap">
                        <div class="d-flex flex-column">
                          <span class="font-weight-bolder mb-25">
                            <b-badge
                              v-if="lastgroup.status == 'pendente'"
                              variant="warning"
                            >
                              {{ lastgroup.status.toUpperCase() }}
                            </b-badge>
                            <b-badge
                              v-if="lastgroup.status == 'aprovado'"
                              variant="success"
                            >
                              {{ lastgroup.status.toUpperCase() }}
                            </b-badge>
                            <b-badge
                              v-if="lastgroup.status == 'recusado'"
                              variant="danger"
                            >
                              {{ lastgroup.status.toUpperCase() }}
                            </b-badge>

                          </span>
                        </div>
                      </td>
                      <td>

                        <b-button
                          variant="danger"
                          @click="recusarGrupo(lastgroup.id)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-x"
                          ><line
                            x1="18"
                            y1="6"
                            x2="6"
                            y2="18"
                          /><line
                            x1="6"
                            y1="6"
                            x2="18"
                            y2="18"
                          /></svg>
                        </b-button>
                      </td>

                    </tr>

                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </b-card>
    <!-- Listagem das Ordens -->
  </div>
</template>

<script>
import {
  BCard, BCardText, BCol, BButton, BLink, BRow, BAlert, BBadge, BCardHeader, BCardTitle, BImg, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import funcs from '@/services/func.service'
import moment from 'moment'

const user = JSON.parse(localStorage.getItem('admin'))

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BImg,
    BButton,
    BLink,
    BRow,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BBadge,
    BAlert,
  },
  data() {
    return {
      nameAdmin: [],
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Vendas',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '0',
          subtitle: 'Grupos Aprovados',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Vencidos',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: 'R$0',
          subtitle: 'Vendas',
          customClass: '',
        },
      ],
      groups: [],
      salles: [],
      groupsApproved: [],
      groupsExpired: [],
    }
  },
  computed: {},
  mounted() {
    this.fetchAdminLoggedIn()
    this.fetchLastGroups()
    this.countSalles()
    this.countGroupsApproved()
    this.countGroupsExpired()
  },
  methods: {
    fetchAdminLoggedIn() {
      this.nameAdmin = user
    },

    countSalles() {
      funcs.countSalles().then(success => {
        if (success.data) {
          const returnRequest = success.data[0]['count(*)']
          this.statisticsItems[0].title = returnRequest
        }
      })
    },
    countGroupsApproved() {
      funcs.countGroupsApproved().then(success => {
        if (success.data) {
          const returnRequest = success.data[0]['count(*)']

          const money = 10 * returnRequest
          this.statisticsItems[1].title = returnRequest
          this.statisticsItems[3].title = `R$${money}`
        }
      })
    },
    countGroupsExpired() {
      funcs.countGroupsExpired().then(success => {
        if (success.data) {
          const returnRequest = success.data[0]['count(*)']
          this.statisticsItems[2].title = returnRequest
        }
      })
    },

    fetchLastGroups() {
      funcs.fetchLastGroups().then(success => {
        console.log(success)
        if (success.data) {
          const returnRequest = success.data
          this.groups = returnRequest
        }
      })
    },

    recusarGrupo(id) {
      const status = { idgrupo: id, status: 'recusado' }
      funcs.updateGroup(status).then(success => {
        if (success.data) {
          this.fetchLastGroups()
        }
      })
    },

    formatDate(date) {
      const newdate = `${moment(date)
        .utc()
        .format('DD/MM/YYYY')}`
      this.date_end = newdate
      return newdate
    },
  },
}
</script>

<style>

</style>
